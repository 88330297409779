
import axios from 'axios';
import { getLoginToken,getApiUrl} from '../helpers/Util';
import {createNotification} from '../helpers/Notification';
import { Route,Redirect } from 'react-router-dom';

 export const getManagerList = async () =>{

    const token = getLoginToken();  
    const headers = {
        "Content-Type":"application/json",
        "Accept":"application/json",
        "Authorization":`Bearer ${token}`
        }
        
        const URL =   getApiUrl();
        const url = `${URL}/users/projectmanagers`;
        let listArr = [];
        await axios.get(url, {
            headers: headers
        })                                                                            
        .then((response) => {
            if(response.status){
            let responseData = response.data.data;
            
            responseData && responseData.map((item) => {
                    listArr.push({lable:item.name.length>18?item.name.substr(0, 20)+' ...':item.name,value:item.id});
            });
           // this.setState({ managerList: listArr });
            }
            //console.log({response:response});
        })
        .catch((error) => {
            const {response} = error;
            // if(response.status === 401){
            //     createNotification("error", "Login Expire!");
            //     return <Redirect to="/login" />  
            // }
            console.log({errorData:error});
        })
        return listArr;
    // }
}

// Helper function to convert a data URL to a Blob
export const dataURLtoBlob = (dataURL) => {
   
    
        const arr = dataURL.split(',');
        const mime = arr[0].match(/:(.*?);/)[1];
        const bstr = atob(arr[1]);
        let n = bstr.length;
        const u8arr = new Uint8Array(n);
      
        while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
        }
      
        return new Blob([u8arr], { type: mime });
  };